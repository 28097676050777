<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import _debounce from 'lodash.debounce';
import EmptyList from "@/components/widgets/empty_list"
import EmailBlockBuilder from "@/components/personalizations/emailBlockBuilder";
import Store from "@/components/personalizations/templates-store.vue"

import {
	templateMethods,
  projectMethods,
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
	title: "Email Components",
	meta: [
		{
			name: "description",
			content: appConfig.description,
		},
	],
  },
  components: {
    Layout,
    PageHeader,
    EmptyList,
    EmailBlockBuilder,
    Store
  },
  data() {
	return {
		templates: [],
    confirmDelete:false,
		currentPage:1,
		perPage:10,
		rows:0,
    isLoading:false,
    templateId:null,
    pageLoaded:false,
    empty_config:{
        "title" : this.$t('email_components.empty_list'),
        "subtitle" : this.$t('email_components.empty_list_subtitle'),
        "buttonText" : this.$t('email_components.new'),
        icon: "fa-cubes"
      },
      empty_list_config:{
          "title" : this.$t("email_components.empty_search_title"),
          "linkButtonText" : this.$t("common.reset_search"),
          icon: "fa-cubes"
      },
      query:'',
      showNewTemplateModal: false,
      showNewTemplate: false,
      isLocal : window.config?.local,
      template: {},
      mode: 'design',
      viewport :'desktop',
      items:[],
      categories:[],
      affinity_Types: [],
      showTemplatesAvailable:false,
      type: ''
    }
  },
  
  mounted() {
    this.loadEmailBlocks();
    this.loadItems();
  },
  created(){
		this.debounceTemplates = _debounce(this.searchTemplates.bind(this), 1000);
	},
  methods: {
	...templateMethods,
  ...projectMethods,

  shuffle(array) {
    // Se recorre el array desde el último elemento hasta el segundo elemento
    for (let i = array.length - 1; i > 0; i--) {
      // Se genera un índice aleatorio entre 0 y i
      const j = Math.floor(Math.random() * (i + 1));
      // Se intercambian los elementos en las posiciones i y j
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  },
  loadItems(){
    const params = {
      q: `whereObj={"project":"${localStorage.getItem('current_project')}", "data.active":1}&limit=100`
    }
    this.getItems(params).then((res)=>{
      this.items = res.data ? this.shuffle(res.data) : [];
    }).catch(()=>{
      this.$notify({ type: 'error', text: this.$t('items.list_get_error'),title:  this.$t('items.title') });
    }).finally(()=>{
      
    })
  },
  onRemoveEmailBlockClicked(templateId){
			this.confirmDelete = true;
			this.templateId = templateId
		},

  onConfirmRemoveEmailBlockClicked(){
    let loader = this.$loading.show();
    this.deleteTemplate(this.templateId).then(()=>{
        this.$notify({ type: 'success', text: this.$t('email_components.delete_email_component_success'),title: this.$t('email_components.title') });
        this.currentPage=1;
        this.loadEmailBlocks();
    }).catch(()=>{
      this.$notify({ type: 'error', text: this.$t('email_components.delete_email_component_error'),title: this.$t('email_components.title') });		
    }).finally(()=>{
        this.isLoading = false;
        loader.hide();
        this.templateId=null;
    })
  },
  loadEmailBlocks(){

    let loader = this.$loading.show();
    const params={
      q: `where[type]=email_block&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
    }
    this.isLoading = true;
    
    this.getTemplates(params).then((res)=>{
        this.templates = res.data;
        this.rows= res.totalCount;
        this.pageLoaded = true;
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('email_components.get_email_components_error'),title: this.$t('email_components.title') });		
      }).finally(()=>{
        this.isLoading = false
        loader.hide();
      })
  },

  onTemplatesListPageClicked(){
    this.loadEmailBlocks();
  },
  onEditEmailBlockClicked(data){
    this.showNewTemplate = true;
    this.template= data;
      
    const loader = this.$loading.show();
    setTimeout(()=>{
      this.$refs.customWebBuilder.setTemplateValue(this.template.value, this.template._id)
      loader.hide();
    },1000)
  },

    searchTemplates(query){
			
			let loader = this.$loading.show();
			this.isLoading = true;
      this.query = query;
      this.empty_list_config.subtitle =  this.$t("email_components.empty_search_text").replaceAll('#', this.query);

			if(query){
				this.currentPage = 1;
						const params={
							q: `where[type]=email_block&where[name][$regex]=.*${query}.*&where[name][$options]=i&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
						}
						this.getTemplates(params).then((res)=>{
							this.templates = res.data;
              this.rows= res.totalCount;
            }).catch(()=>{
							this.$notify({ type: 'error', text: this.$t('email_components.get_email_components_error'),title: this.$t('email_components.title') });
						}).finally(()=>{
							this.isLoading = false;
							loader.hide();
						});
			}else{
				this.isLoading = false;
				loader.hide();
				this.currentPage = 1;
				this.loadEmailBlocks();
			}
		},

    getTemplateType(type){
      switch(type){
        case 'email_reco': return this.$t('email_components.subtype_email_reco'); 
        case 'email_dynamic': return this.$t('email_components.subtype_email_dynamic');
      }
      return type;
    },
    onResetSearchClicked(){
      this.query = '';
      this.loadEmailBlocks();
    },
    onCreateEmailBlock(type){
      this.showNewTemplateModal = false;
      this.showTemplatesAvailable = true;

      this.template= {
        type: 'email_block',
        subType: type,
        name: 'New Email Block',
        value: {}
      }
    },
    setViewport(viewport){
      this.viewport= viewport;
      this.$refs.customWebBuilder.onResolutionChanged(viewport);
    },
    setMode(mode){
      this.mode = mode;
      this.$refs.customWebBuilder.onSetMode(mode);
    },

    onConfirmSaveEmailBlockClicked(save){

      this.template.value = this.$refs.customWebBuilder.getTemplatesValues();

      if(this.template?._id){
        let loader = this.$loading.show();
        this.template.project = localStorage.getItem('current_project');
        this.updateTemplate(this.template).then(()=>{
          this.$notify({ type: 'success', text: this.$t('email_components.update_email_component_success'),title:  this.$t('email_components.title') });

          if(!save)
            this.showNewTemplate = false;
          else{
            this.currentPage = 1;
            this.query = '';
            this.loadEmailBlocks();
          }
          
        }).catch(()=>{
          this.$notify({ type: 'error', text: this.$t('email_components.update_email_component_error'),title:  this.$t('email_components.title') });
        }).finally(()=>{
          loader.hide();
        })
      }else{
        let loader = this.$loading.show();
        this.template.project = localStorage.getItem('current_project');
        this.createTemplate(this.template).then((res)=>{
          // eslint-disable-next-line no-console
          console.log(res.data.data._id);
          if(res.data?.data?._id){
            this.template.value.html = this.template.value.html.replace('gs_TemplateId', res.data.data._id)
            this.template.value.exportedHTML = this.template.value.exportedHTML.replace('gs_TemplateId', res.data.data._id)
            this.updateTemplate({_id: res.data.data._id,value: this.template.value}).finally(()=>{
              this.$notify({ type: 'success', text: this.$t('email_components.create_email_component_success'),title: this.$t('email_components.title')  });
              this.currentPage = 1;
              this.query = '';
              this.showNewTemplate = false;
              this.loadEmailBlocks();
            });
          }
          
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('email_components.create_email_component_error'),title:  this.$t('email_components.title')  });
      }).finally(()=>{
        loader.hide();
      })
      }
    },
    onTemplateClicked(template){
      this.showTemplatesAvailable = false;
      this.template.value = template.value;
      this.showNewTemplate = true;

      const loader = this.$loading.show();
        setTimeout(()=>{
          this.$refs.customWebBuilder.setTemplateValue(this.template.value, this.template._id || 'gs_TemplateId')
          loader.hide();
        },1000)
    },
    onCopyEmailBlockClicked(temp){
      this.template = Object.assign({}, temp);
      this.template.name = `Copy ${temp.name}`;
      delete this.template._id; 
      
      this.showNewTemplate = true;
      
      const loader = this.$loading.show();
        setTimeout(()=>{
          this.$refs.customWebBuilder.setTemplateValue(this.template.value, this.template._id || 'gs_TemplateId')
          loader.hide();
        },1000)
    }
  },
  
};
</script>

<template>
	<Layout>
		<PageHeader :title="$t('email_components.title')" :items="[]" />
		<div class="row">
      <div class="col-sm-12">
        <div class="card mb-3">  
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="search-box me-2 mb-0 d-inline-block">
                        <div class="position-relative">
                            <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('common.search')"
                            @input="debounceTemplates($event.target.value)"
                            v-model="query"
                            />
                            <i class="bx bx-search-alt search-icon"></i>
                        </div>
                        </div>
                    </div>
                    <div class="col-sm-8">
                        <div class="text-sm-end">
                          <button
                              type="button"
                              class="btn btn-primary  mb-0 me-0"
                              @click="showNewTemplateModal = true"
                              v-if="templates.length>0 || query!=''">
                              <i class="mdi mdi-plus me-1"></i> {{$t('email_components.new')}}
                          </button>
                        </div>
                    </div>
                </div>
                <EmptyList :config="empty_list_config" v-if="!isLoading && templates.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
                <EmptyList :config="empty_config" v-if=" !isLoading && templates.length == 0 && query == ''" @onButtonClicked="showNewTemplateModal = true" class="mt-3"/>
            </div>
            <div class="table-responsive mb-0" v-if="pageLoaded && templates.length > 0">
              <table  id="my-table" class="table align-middle table-nowrap table-hover mb-0">
                <thead class="table-light">
                  <tr>
                    <th scope="col">{{$t('email_components.name')}}</th>
                    <th scope="col">{{$t('email_components.type')}} </th>
                    <th scope="col">{{$t('email_components.last_modification')}}</th>
                    <th scope="col" class="text-end"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="template in templates" :key="template._id">
                    <td>
                      <h5 class="font-size-14 mb-1" v-on:click="onEditEmailBlockClicked(template)">
                        <a href="#" class="text-dark">{{template.name}}</a>
                      </h5>
                    </td>
								<td>{{ getTemplateType(template.subType)}}</td>
                <td>{{template.updatedAt ? `${new Date(template.updatedAt).toLocaleDateString()} ${new Date(template.updatedAt).toLocaleTimeString()}` : ''}}</td>
								<td class="text-end">
                  <i class="fas fa-clone text-info me-2 interact" v-on:click="onCopyEmailBlockClicked(template)"></i>
                  <i class="fas fa-edit text-success me-2 interact" v-on:click="onEditEmailBlockClicked(template)"></i>
                  <i class="fas fa-trash-alt text-danger me-1 interact" v-on:click="onRemoveEmailBlockClicked(template._id)"></i>
								</td>
							</tr>
                </tbody>
              </table>
            </div>
        </div>
        <div class="row mb-3" v-if="rows > perPage">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      @input="onTemplatesListPageClicked"
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage">
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </div>
      </div>
        <b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemoveEmailBlockClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
            <p>{{ $t('email_components.remove_email_component') }}</p>
        </b-modal>
        <b-modal
        v-model = "showNewTemplateModal"
        title-class="font-18"
        hide-footer
        :title="$t('email_components.new')">
        <div class="list-group list-group-flush">
          <a href="javascript:void(0);" class="list-group-item text-muted py-2 px-0" v-on:click="onCreateEmailBlock('email_reco')">
              <div class="d-flex align-items-center">
                <div class="me-3">
                  <img
                    src="@/assets/images/brands/email-reco.png"
                    alt=""
                    class="avatar-sm h-auto d-block rounded"
                  />
                </div>
                <div class="flex-grow-1 overflow-hidden">
                  <h4 class="font-size-13 text-truncate mb-1">
                    {{ $t('email_components.subtype_email_reco') }}
                  </h4>
                  <p class="small mb-0">{{ $t('email_components.subtype_email_reco_subtitle') }}</p>
                </div>
              </div>
            </a>
            <a href="javascript:void(0);" class="list-group-item text-muted py-2 px-0" v-on:click="onCreateEmailBlock('email_dynamic')">
              <div class="d-flex align-items-center">
                <div class="me-3">
                  <img
                  src="@/assets/images/brands/email-content.png"
                    alt=""
                    class="avatar-sm h-auto d-block rounded"
                  />
                </div>
                <div class="flex-grow-1 overflow-hidden">
                  <h4 class="font-size-13 text-truncate mb-1">
                    {{ $t('email_components.subtype_email_dynamic') }}
                  </h4>
                  <p class="small mb-0">{{ $t('email_components.subtype_email_dynamic_subtitle') }}</p>
                </div>
              </div>
            </a>
          </div>
        </b-modal>
        <b-modal
        v-model="showNewTemplate"
					id="modal-xl"
          size="xl"
					title-class="font-18"
          no-close-on-backdrop 
          scrollable>
          <template #modal-header>
            
            <div class="left-content">
              <input class="form-control" v-model="template.name" style="font-weight: bold;font-weight: bold; width: 185px;"/>
              <div class="btn-group">
                <button type="button" :class="viewport =='desktop' ?'btn btn-primary' : 'btn btn-outline-primary'" v-if="false" @click="setMode('preview')"  >
                  {{$t('personalizations.experience_variant_mode_preview')}}
                </button>
                <button type="button" :class="mode =='design' ?'btn btn-primary' : 'btn btn-outline-primary'" @click="setMode('design')" >
                  {{$t('personalizations.experience_variant_mode_design')}}
                </button>
                <button type="button" :class="mode =='code' ?'btn btn-primary' : 'btn btn-outline-primary'" @click="setMode('code')" >
                  {{$t('personalizations.experience_variant_mode_advanced')}}
                </button>
              </div>
            </div>
            <div class="center-content" v-if="mode=='design'">
              <div class="btn-group viewport-controls">
                <button type="button" :class="viewport =='desktop' ?'btn btn-primary' : 'btn btn-outline-primary'" @click="setViewport('desktop')"  >
                  <i class="mdi mdi-desktop-mac"></i>
                </button>
                <button type="button" :class="viewport =='tablet' ?'btn btn-primary' : 'btn btn-outline-primary'" @click="setViewport('tablet')">
                  <i class="mdi mdi-tablet-ipad"></i>
                </button>
                <button type="button" :class="viewport =='mobile' ?'btn btn-primary' : 'btn btn-outline-primary'" @click="setViewport('mobile')">
                  <i class="mdi mdi-cellphone-iphone"></i>
                </button>
              </div>
            </div>
          </template>
          <template #modal-footer>
            <b-button variant="secondary" class="me-2" @click="showNewTemplate = false">{{$t('common.cancel')}}</b-button>
            <button class="btn btn-outline-primary me-2" @click="onConfirmSaveEmailBlockClicked(true)" v-if="template?._id">{{$t('common.save')}}</button>
            <b-button variant="primary" class="btn btn-primary" @click.prevent="onConfirmSaveEmailBlockClicked(false)">{{ $t('common.confirm')  }}</b-button>
          </template>
          <EmailBlockBuilder ref="customWebBuilder" :items="items" :affinity_Types="affinity_Types" :categories="categories" :reco_email="true"/>
      </b-modal>
      <b-modal id="templates-modal" size="xl" v-model="showTemplatesAvailable" :title="$t('personalizations.experience_variant_start_with')" hide-footer no-close-on-backdrop>
        <Store :subType="'email_reco'" :hideSubtypeFilter="true" :hideGoalsFilter="true" :showFromScratch="true" @selectStoreTemplate="onTemplateClicked" :showMyTemplates="false" :type="'email_block'" :hideTypeBadge="true"/>
      </b-modal>
    </div>
  </Layout>
</template>

<style scoped>
.interact{
	cursor: pointer;
  }

.bold{
	font-weight: bold;
}

.modal-xl{
  --bs-modal-width: 95% !important;
} 
.left-content {
    display: flex;
    align-items: center;
    gap: 10px; /* Optional: Adjust spacing between input and buttons */
}

.center-content {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
}

</style>

<style>
#templates-modal .modal-body {
  background-color: var(--bs-body-bg);
  min-height: 500px;
}

</style>